import React from "react";
import {Link} from "react-router-dom";
import axios from 'util/Api'
import { apiUrl } from 'util/config'
import IntlMessages from "util/IntlMessages";
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Icon,
  Input, InputNumber,
  Menu, Modal,
  Radio,
  Row, Select, Switch,
  Table, TimePicker,
  TreeSelect,
} from "antd";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {B, T} from "../../helpers";
import moment from "moment";
import _ from "lodash";


const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;
const InputGroup = Input.Group;


const defaultVisaDocument = {
  visaProcessId: '',
  description: '',
  country: '',
  dueDate: '',
  tripEndDate: '',
  tripStartDate: '',
  status: 'waiting',
  visaRequestItems: [],
};

class Request extends React.Component {

  state = {
    employees: [],
    modal: false,
    visaDocument: defaultVisaDocument,
    loadingModal: false,
    visaProcesses: [],
  };

  componentDidMount() {
    this.props.shareMethods(this.openVisaDocumentModal.bind(this),"openVisaDocumentModal");
    this.localizeDateTime();
    this.getVisaProcesses();
  }

  getVisaProcesses = () => {
    const { id } = this.props.company;
    const { token } = this.props;
    this.setState({ loadingTable: true });
    axios.get('api/visaProcess/getAll/'+id, {
      params: {
        routeCode: 'VisaProcess-1'
      },
      headers: {
        'x-access-token': token
      }
    }).then(({data}) => {
      if (data.result) {
        console.log(data);
        data.visaProcesses.map((visa) => {
          visa.visaProcessItems.map((item) => {
            item.oldFile = item.file;
          })
        });

        this.setState({
          visaProcesses: data.visaProcesses
        });
      } else {
        console.log(data);
      }
      this.setState({ loadingTable: false });
    }).catch(function (error) {
      console.log(error.message);
    });
  };

  openVisaDocumentModal() {
    this.props.form.resetFields();
    this.setState({ visaDocument: defaultVisaDocument, modal: true });
  };

  closeModal = () => {
    this.setState({modal: false});
  };

  localizeDateTime = () => {
    const intl = this.props.intl;
    const lng_code = intl.formatMessage({id:'options.dateTimeLocale'});
    import('moment/locale/'+lng_code);
  };

  handleModalOk = () => {

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loadingModal: true});
        axios.post('api/visaRequest/request', {
          routeCode: 'VisaRequest-6',
          visaRequest: this.state.visaDocument
        }).then(({data}) => {
          this.setState({ loadingModal: false });
          if (data.result) {
            console.log(data);
            T.toast('success', 'requestDone');
            this.setState({ modal: false });
          } else {
            console.log(data);
            T.toast('error');
          }
        }).catch((error) => {
          console.log(error.message);
          T.toast('error');
        });
      }
    });

  };

  handleProcessItemChange = (checked, item) => {
    let { visaRequestItems } = this.state.visaDocument;
    if(checked){
      visaRequestItems.push({
        file: '',
        visaProcessItemId: item.id,
        status: item.file ? 'uploaded' : 'waiting'
      })
    }else{
      visaRequestItems = _.remove(visaRequestItems, function(i) {
        return i.visaProcessItemId !== item.id;
      })
    }

    this.setState({visaDocument: {...this.state.visaDocument, visaRequestItems}});
  };

  handleInputChange = (event, state = 'visaDocument') => {

    if(Array.isArray(event)){
      const inputs = {};
      event.map((input) => {
        inputs[input.name] = input.value;
      });

      this.setState({
        [state]: { ...this.state[state], ...inputs}
      });
      setTimeout(() => {
        const { startDate, endDate } = this.state.visaDocument;
        const usedDays = startDate && endDate ? moment(endDate).diff(moment(startDate), 'days')+
          (((moment(endDate).unix()-moment(startDate).unix()) % 86400) >= 18000 ? 1 : 0.5) : 0;
        this.setState({visaDocument: {...this.state.visaDocument, usedDays}});
      }, 200);


    }else {
      let name, value;
      if(event.target){
        const target = event.target;
        value = target.type === 'checkbox' ? target.checked : target.value;
        name = target.id;
      }else{
        value = event.value;
        name = event.name;
      }
      this.setState({
        [state]: { ...this.state[state],
          [name]: value
        }
      });
    }

    setTimeout(() => {console.log(this.state)}, 200);
  };

  render(){
    const intl = this.props.intl;
    const dateFormat = intl.formatMessage({id:'options.dateTimeFormat'});
    const {getFieldDecorator} = this.props.form;

    const { loadingModal, modal, visaProcesses, visaProcess } = this.state;

    const { dueDate, tripEndDate, tripStartDate, country, description, visaProcessId, visaRequestItems } = this.state.visaDocument;
    return (
      <Modal
        width={650}
        visible={modal}
        title={<IntlMessages id="requests.requestVisaDocument"/>}
        onOk={this.handleModalOk}
        onCancel={this.closeModal}
        footer={[
          <Button key="back" onClick={this.closeModal}><IntlMessages id="common.cancel"/></Button>,
          <Button key="submit" type="primary" loading={loadingModal} onClick={this.handleModalOk}>
            <IntlMessages id="common.save"/>
          </Button>,
        ]}
      >
        <Form>
          <Row>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="table.dueDate"/>
                )}
              >
                {getFieldDecorator('dueDate', {
                  initialValue: dueDate ? moment(dueDate) : null,
                  rules: [{type: 'object', required: true, message: <IntlMessages id="validation.selectDate"/>}],
                })(
                  <DatePicker
                    className="gx-mb-3 gx-w-100"
                    format={dateFormat}
                    onChange={(_, date) => { this.handleInputChange({name:'dueDate', value: moment(date, dateFormat).format(B.dateFormat)}) }}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="employees.description"/>
                )}
              >
                {getFieldDecorator('description', {
                  initialValue: description,
                })(
                  <Input onChange={this.handleInputChange}/>
                )}
              </FormItem>
            </Col>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="table.tripStartDate"/>
                )}
              >
                {getFieldDecorator('tripStartDate', {
                  initialValue: tripStartDate ? moment(tripStartDate) : null,
                  rules: [{type: 'object', required: true, message: <IntlMessages id="validation.selectDate"/>}],
                })(
                  <DatePicker
                    className="gx-mb-3 gx-w-100"
                    format={dateFormat}
                    onChange={(_, date) => { this.handleInputChange({name:'tripStartDate', value: moment(date, dateFormat).format(B.dateFormat)}) }}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="table.tripEndDate"/>
                )}
              >
                {getFieldDecorator('tripEndDate', {
                  initialValue: tripEndDate ? moment(tripEndDate) : null,
                  rules: [{type: 'object', required: true, message: <IntlMessages id="validation.selectDate"/>}],
                })(
                  <DatePicker
                    className="gx-mb-3 gx-w-100"
                    format={dateFormat}
                    onChange={(_, date) => { this.handleInputChange({name:'tripEndDate', value: moment(date, dateFormat).format(B.dateFormat)}) }}
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="employees.country"/>
                )}
              >
                {getFieldDecorator('country', {
                  initialValue: country,
                  rules: [{required: true, message: intl.formatMessage({id:'validation.required'})}],
                })(
                  <Input onChange={this.handleInputChange}/>
                )}
              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="employees.visaProcess"/>
                )}
              >
                {getFieldDecorator('visaProcessId', {
                  initialValue: visaProcessId,
                  rules: [{required: true, message: intl.formatMessage({id:'validation.required'})}],
                })(
                  <Select onChange={(value) => {
                    this.setState({visaProcess: _.find(visaProcesses, { 'id': value })});
                    this.handleInputChange({name:'visaProcessId', value})
                  }}>
                    { visaProcesses.map((type) => <Option key={type.id} value={type.id}>{type.name}</Option>) }
                  </Select>
                )}
              </FormItem>
            </Col>
            {
              visaProcessId &&
              (
                <Col xs={24}>
                  <Row style={{padding: 10}}>
                    <Col xs={6}></Col>
                    <Col xs={6}>Dosya Adı</Col>
                    <Col xs={6}>Dosya Açıklaması</Col>
                  </Row>
                  {
                    visaProcess.visaProcessItems.map((item, index) => {
                      return (
                        <Row style={{padding: 10, backgroundColor: index % 2 === 0 ? '#f4f4f4' : '#fff'}} key={index}>
                          <Col xs={6}>
                            <Switch defaultChecked={_.find(visaRequestItems, { 'visaProcessItemId': item.id })} checkedChildren={<Icon type="check"/>} unCheckedChildren={<Icon type="cross"/>}
                                    onChange={(checked) => { this.handleProcessItemChange(checked, item)}}
                            /> <IntlMessages id="common.add"/>
                          </Col>
                          <Col xs={6}>{item.name}</Col>
                          <Col xs={6}>{item.description}</Col>
                        </Row>
                      )
                    })
                  }
                </Col>
              )
            }
          </Row>
        </Form>
      </Modal>
    );
  };
}



const RequestForm = Form.create()(injectIntl(Request));

const mapStateToProps = ({auth, settings}) => {
  const {token, company} = auth;
  const {locale} = settings;
  return {locale, token, company}
};


export default connect(mapStateToProps)(RequestForm);
