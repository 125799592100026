import React, {Component} from "react";
import {Card, Icon, Menu} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "util/IntlMessages";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


class NavigationTop extends Component {
  state = {
    current: this.props.route,
  };

  render() {
    const {userId} = this.props;
    return (
      <Card className="gx-card gx-mb-2">
        <Menu
          onClick={this.handleClick}
          selectedKeys={[this.state.current]}
          mode="horizontal"
        >
          <Menu.Item key="companyProfile">
            <Link to='/settings/company-profile/'><IntlMessages id="breadcrumb.company-profile"/></Link>
          </Menu.Item>
          <Menu.Item key="leaveRules">
            <Link to='/settings/leave-rules/'><IntlMessages id="breadcrumb.leave-rules"/></Link>
          </Menu.Item>
          <Menu.Item key="visaProcess">
            <Link to='/settings/visa-process/'><IntlMessages id="breadcrumb.visa-process"/></Link>
          </Menu.Item>
          <Menu.Item key="asset">
            <Link to='/settings/asset/'><IntlMessages id="breadcrumb.asset"/></Link>
          </Menu.Item>
          <Menu.Item key="workingDays">
            <Link to='/settings/working-days/'><IntlMessages id="breadcrumb.working-days"/></Link>
          </Menu.Item>
          <Menu.Item key="employment">
            <Link to='/settings/employment-termination-reason/'><IntlMessages id="breadcrumb.employment-termination-reason"/></Link>
          </Menu.Item>

          <Menu.Item key="trainings">
            <Link to='/settings/trainings/'><IntlMessages id="breadcrumb.trainings"/></Link>
          </Menu.Item>

        </Menu>
      </Card>
    );
  }

}


export default NavigationTop;
