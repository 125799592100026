import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}employees/new-employee`} component={asyncComponent(() => import('./Employees/New'))}/>
      <Route path={`${match.url}employees/general/:userId`} component={asyncComponent(() => import('./Employees/General'))}/>
      <Route path={`${match.url}employees/career/:userId`} component={asyncComponent(() => import('./Employees/Career'))}/>
      <Route path={`${match.url}employees/personalInfo/:userId`} component={asyncComponent(() => import('./Employees/PersonalInfo'))}/>
      <Route path={`${match.url}employees/otherInfo/:userId`} component={asyncComponent(() => import('./Employees/OtherInfo'))}/>
      <Route path={`${match.url}employees/timeOff/:userId`} component={asyncComponent(() => import('./Employees/TimeOff'))}/>
      <Route path={`${match.url}employees/transactions/:userId`} component={asyncComponent(() => import('./Employees/Transactions'))}/>
      <Route path={`${match.url}employees/overtime/:userId`} component={asyncComponent(() => import('./Employees/Overtime'))}/>
      <Route path={`${match.url}employees/assets/:userId`} component={asyncComponent(() => import('./Employees/Assets'))}/>
      <Route path={`${match.url}employees/trainings/:userId`} component={asyncComponent(() => import('./Employees/Trainings'))}/>
      <Route path={`${match.url}employees/files/:userId`} component={asyncComponent(() => import('./Employees/Files'))}/>
      <Route path={`${match.url}employees/visa-requests/:userId`} component={asyncComponent(() => import('./Employees/VisaRequests'))}/>
      <Route path={`${match.url}employees`} component={asyncComponent(() => import('./Employees'))}/>
      <Route path={`${match.url}settings/trainings`} component={asyncComponent(() => import('./Settings/Trainings'))}/>
      <Route path={`${match.url}settings/leave-rules`} component={asyncComponent(() => import('./Settings/LeaveRules'))}/>
      <Route path={`${match.url}settings/asset`} component={asyncComponent(() => import('./Settings/AssetCategories'))}/>
      <Route path={`${match.url}settings/employment-termination-reason`} component={asyncComponent(() => import('./Settings/EmploymentTerminationReason'))}/>
      <Route path={`${match.url}settings/working-days`} component={asyncComponent(() => import('./Settings/WorkingDays'))}/>
      <Route path={`${match.url}settings/visa-process`} component={asyncComponent(() => import('./Settings/VisaProcesses'))}/>
      <Route path={`${match.url}settings/company-profile`} component={asyncComponent(() => import('./Settings/CompanyProfile'))}/>
      <Route path={`${match.url}settings`} render={() => <Redirect to='/settings/company-profile' /> } />
      <Route path={`${match.url}calendar`} component={asyncComponent(() => import('./Calendar'))}/>
      <Route path={`${match.url}leaves/reports`} component={asyncComponent(() => import('./Leaves/Reports'))}/>
      <Route path={`${match.url}leaves`} component={asyncComponent(() => import('./Leaves'))}/>
      <Route path={`${match.url}reports/ageDistribution`} component={asyncComponent(() => import('./Reports/AgeDistribution'))}/>
      <Route path={`${match.url}reports/genderDistribution`} component={asyncComponent(() => import('./Reports/GenderDistribution'))}/>
      <Route path={`${match.url}reports/militaryService`} component={asyncComponent(() => import('./Reports/MilitaryService'))}/>
      <Route path={`${match.url}reports/employeeEducationalStatus`} component={asyncComponent(() => import('./Reports/EmployeeEducationalStatus'))}/>
      <Route path={`${match.url}reports/disabledEmployees`} component={asyncComponent(() => import('./Reports/DisabledEmployees'))}/>
      <Route path={`${match.url}reports/employeeList`} component={asyncComponent(() => import('./Reports/EmployeeList'))}/>
      <Route path={`${match.url}reports/employeePaymentInfo`} component={asyncComponent(() => import('./Reports/EmployeePaymentInfo'))}/>
      <Route path={`${match.url}reports/birthdayList`} component={asyncComponent(() => import('./Reports/BirthdayList'))}/>
      <Route path={`${match.url}reports/trainingList`} component={asyncComponent(() => import('./Reports/TrainingList'))}/>
      <Route path={`${match.url}reports/unitsDistribution`} component={asyncComponent(() => import('./Reports/UnitsDistribution'))}/>
      <Route path={`${match.url}reports/bloodTypeDistribution`} component={asyncComponent(() => import('./Reports/BloodTypeDistribution'))}/>
      <Route path={`${match.url}reports/communicationInformation`} component={asyncComponent(() => import('./Reports/CommunicationInformation'))}/>
      <Route path={`${match.url}reports/employmentDuration`} component={asyncComponent(() => import('./Reports/EmploymentDuration'))}/>
      <Route path={`${match.url}reports/leaveRule`} component={asyncComponent(() => import('./Reports/LeaveRule'))}/>
      <Route path={`${match.url}reports/hiring`} component={asyncComponent(() => import('./Reports/Hiring'))}/>
      <Route path={`${match.url}reports/terminatedEmployees`} component={asyncComponent(() => import('./Reports/TerminatedEmployees'))}/>
      <Route path={`${match.url}reports/workingDayCalendar`} component={asyncComponent(() => import('./Reports/WorkingDayCalendar'))}/>
      <Route path={`${match.url}reports/assetList`} component={asyncComponent(() => import('./Reports/AssetList'))}/>
      <Route path={`${match.url}reports`} component={asyncComponent(() => import('./Reports'))}/>
      <Route path={`${match.url}home`} component={asyncComponent(() => import('./Home'))}/>
      <Route path={`${match.url}profile/settings`} component={asyncComponent(() => import('./Profile/Career'))}/>
      <Route path={`${match.url}profile/career`} component={asyncComponent(() => import('./Profile/Career'))}/>
      <Route path={`${match.url}profile/timeOff`} component={asyncComponent(() => import('./Profile/TimeOff'))}/>
      <Route path={`${match.url}profile/transactions`} component={asyncComponent(() => import('./Profile/Transactions'))}/>
      <Route path={`${match.url}profile/overtime`} component={asyncComponent(() => import('./Profile/Overtime'))}/>
      <Route path={`${match.url}profile/salary`} component={asyncComponent(() => import('./Profile/Salary'))}/>
      <Route path={`${match.url}profile/trainings`} component={asyncComponent(() => import('./Profile/Trainings'))}/>
      <Route path={`${match.url}profile/visa-requests`} component={asyncComponent(() => import('./Profile/VisaRequests'))}/>
      <Route path={`${match.url}profile`} render={() => <Redirect to='/profile/settings' /> }/>
    </Switch>
  </div>
);

export default App;
