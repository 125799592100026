import React from "react";
import {connect} from "react-redux";
import SettingsAssetCategoriesForm from "../Settings/AssetCategories";



class Startup extends React.Component {

  componentDidMount() {
    if(this.props.company.complated){
      this.props.history.push("/home")
    }
  };

  render(){

    return (
      <div>
        <SettingsAssetCategoriesForm/>
       <p>test</p>
       <p>test</p>
       <p>test</p>
       <p>test</p>
       <p>test</p>
       <p>test</p>
       <p>test</p>
       <p>test</p>
       <p>test</p>
       <p>test</p>
       <p>test</p>
      </div>
    );
  };
}


const mapStateToProps = ({auth}) => {
  const {token, company} = auth;
  return {token, company}
};


export default connect(mapStateToProps)(Startup);
