import React from "react";
import {Button, Checkbox, Form, Input, Layout, Row, Col} from "antd";
import {Link} from "react-router-dom";

import {connect} from "react-redux";
import {userSignUp} from "../appRedux/actions/Auth";
import {injectIntl} from 'react-intl';
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import {Language} from "./Topbar/Language";
import {switchLanguage} from "../appRedux/actions";

const FormItem = Form.Item;
const {Header} = Layout;

class SignUp extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phone: '',
    password: '',
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userSignUp(values);
      }
    });
  };


  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/home');
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const intl = this.props.intl;

    return (
      <div className="gx-app-login-wrap">
        <Header style={{position:'absolute',top:0,left:0,right:0}}>
          <Language switchLanguage={this.props.switchLanguage}/>
        </Header>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src={require("assets/images/bg.jpg")} alt='Neature'/>
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signUp"/></h1>
                <p><IntlMessages id="nedenhrm"/></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/hrm.png")}/>
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
                <Row>
                  <Col sm={12} xs={24} style={{padding: '0 5px 0 0'}}>
                    <FormItem>
                      {getFieldDecorator('firstName', {
                        rules: [{required: true, message: intl.formatMessage({id:'validation.required'})}],
                      })(
                        <Input placeholder={intl.formatMessage({id:'employees.firstName'})}/>
                      )}
                    </FormItem>
                  </Col>
                  <Col sm={12} xs={24} style={{padding: '0 0 0 5px'}}>
                    <FormItem>
                      {getFieldDecorator('lastName', {
                        rules: [{required: true, message: intl.formatMessage({id:'validation.required'})}],
                      })(
                        <Input placeholder={intl.formatMessage({id:'employees.lastName'})}/>
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <FormItem>
                  {getFieldDecorator('company', {
                    rules: [{required: true, message: intl.formatMessage({id:'validation.required'})}],
                  })(
                    <Input placeholder={intl.formatMessage({id:'employees.company'})}/>
                  )}
                </FormItem>

                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{
                      required: true, type: 'email', message: intl.formatMessage({id:'validation.invalidEmail'}),
                    }],
                  })(
                    <Input placeholder={intl.formatMessage({id:'appModule.email'})}/>
                  )}
                </FormItem>

                <FormItem>
                  {getFieldDecorator('phone', {
                    rules: [{required: true, message: intl.formatMessage({id:'validation.required'})}],
                  })(
                    <Input placeholder={intl.formatMessage({id:'appModule.phone'})}/>
                  )}
                </FormItem>

                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{required: true, message: intl.formatMessage({id:'validation.requiredPassword'})}],
                  })(
                    <Input type="password" placeholder={intl.formatMessage({id:'appModule.password'})}/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('key', {
                    rules: [{required: true, message: intl.formatMessage({id:'validation.required'})}],
                  })(
                    <Input placeholder={"Key"}/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: false,
                    rules: [{
                      required: true,
                      transform: value => (value || undefined),
                      type: 'boolean',
                      message: intl.formatMessage({id:'validation.termsConditions'})}],
                  })(
                    <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                  )}
                  <span className="gx-link gx-signup-form-forgot"><IntlMessages
                    id="appModule.termAndCondition"/></span>
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signUp"/>
                  </Button>
                  <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin"><IntlMessages
                  id="app.userAuth.signIn"/></Link>
                </FormItem>
              </Form>
            </div>
            <InfoView/>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedSignUpForm = Form.create()(injectIntl(SignUp));

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userSignUp, switchLanguage})(WrappedSignUpForm);
