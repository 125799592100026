import languageData from "./languageData";
import React from "react";
import {Popover} from "antd";
import {T} from "../../helpers";

import CustomScrollbars from "../../util/CustomScrollbars";

export class Language extends React.Component{

    constructor(props){
        super(props);
        let language = T.cookie('language');

        if(!language){
          T.cookie('language', languageData[0]);
          language = languageData[0];
        }
        this.state={
            language,
            loading: false
        };

        if(language){
            this.props.switchLanguage(language)
        }
    }
    
    languageMenu = () => (
        <CustomScrollbars className="gx-popover-lang-scroll">
            <ul className="gx-sub-popover">
                {languageData.map(language =>
                    <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>{
                        this.props.switchLanguage(language);
                        this.setState({ language });
                        localStorage.setItem("language", JSON.stringify(language));
                    }
                    }>
                        <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
                        <span className="gx-language-text">{language.name}</span>
                    </li>
                )}
            </ul>
        </CustomScrollbars>);

    render() {
        return(
            <ul className="gx-header-notifications gx-ml-auto">
                <li className="gx-language">
                    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={this.languageMenu()} trigger="click">
                        <span className="gx-pointer gx-flex-row gx-align-items-center">
                          <i className={`flag flag-24 flag-${this.state.language.icon}`}/>
                          <span className="gx-pl-2 gx-language-name">{this.state.language.name}</span>
                          <i className="icon icon-chevron-down gx-pl-2"/>
                        </span>
                    </Popover>
                </li>
            </ul>
        )
    }
}


