import IntlMessages from "util/IntlMessages";
import React from "react";
import {Tooltip} from "antd";
import moment from 'moment';
import AppLocale from "../lngProvider";
import {T} from "./index";

export default function columnCreator (labelArray, generalType = 'string', sortedInfo) {

  const locale = T.cookie('language');
  const lng = AppLocale[locale.locale].messages;

  let returnedObj = [];
  labelArray.forEach((label) => {
    let pushedObj, title, dataIndex, sorter, sortOrder, render;
    if(typeof label === "object"){
      if('tooltip' in label){
        title = <Tooltip title={<IntlMessages id={"table."+label.tooltip}/>}><div><IntlMessages id={"table."+label.title}/></div></Tooltip>;
      }else{
        title = <IntlMessages id={"table."+label.title}/>;
      }
      if(!('key' in label)){
        dataIndex = label.title;
        sortOrder= sortedInfo.columnKey === dataIndex && sortedInfo.order;
      }else{
        dataIndex = label.key;
        sortOrder= sortedInfo.columnKey === dataIndex && sortedInfo.order;
      }
      if(!('type' in label)){
        label.type = generalType
      }
      if('render' in label){
        render = label.render
      }
      if(label.type === 'string'){
        sorter = (a, b) => {
          a[dataIndex] = a[dataIndex] === null ? '' : a[dataIndex];
          return(a[dataIndex].localeCompare(b[dataIndex]));
        }
      }else if (label.type === 'date'){
        sorter = (a, b) => moment(a[dataIndex]).unix() - moment(b[dataIndex]).unix();
      } else {
        sorter = (a, b) => a[dataIndex] - b[dataIndex];
      }

      if(label.type === 'date'){
        if('format' in label) {
          render = date => date ? moment(date).format(label.format) : '...'
        } else {
          render = date => date ? moment(date).format(lng['options.dateTimeFormat']) : '...'
        }
      }

      if(('order' in label) && !label.order){
        pushedObj = { title, dataIndex, render }
      }else{
        pushedObj = { title, dataIndex, sorter, sortOrder, render }
      }

      if(('raw' in label) && label.raw){
        pushedObj = label
      }

    }else {
      title = <IntlMessages id={"table."+label}/>;
      dataIndex = label;
      if(generalType === 'string'){
        sorter = (a, b) => {
          a[dataIndex] = a[dataIndex] === null ? '' : a[dataIndex];
          return(a[dataIndex].localeCompare(b[dataIndex]));
        }
      }else if (generalType === 'number'){
        sorter = (a, b) => a[dataIndex] - b[dataIndex];
      }
      sortOrder= sortedInfo.columnKey === dataIndex && sortedInfo.order;
      pushedObj = { title, dataIndex, sorter, sortOrder }
    }
    returnedObj.push(pushedObj);
  });

  return returnedObj
};
