import {INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET, COMPANY_INFO_SET} from "../../constants/ActionTypes";
import {cookie} from "../../helpers/tools"

const INIT_STATE = {
  token: cookie('token'),
  initURL: '',
  user: cookie('user'),
  company: cookie('company'),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        user: null,
        initURL: ''
      }
    }

    case USER_DATA: {
      return {
        ...state,
        user: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case COMPANY_INFO_SET: {
      return {
        ...state,
        company: action.payload,
      };
    }

    default:
      return state;
  }
}
