import React, {Component} from "react";
import {connect} from "react-redux";
import axios from 'util/Api'
import {Avatar, Button, Col, DatePicker, Form, Icon, Input, Modal, Popover, Row, Select, Switch} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from "react-intl";
import {B, T} from "../../helpers";

const FormItem = Form.Item;

class ChangePassword extends Component {

  state = {
    changePassModal: false,
    loadingModal: false,
    user: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(<IntlMessages id="validation.inconsistentPasswords"/>);
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.confirmDirty) {
      form.validateFields(['confirmNewPassword'], {force: true});
    }
    callback();
  };

  handleModalOk = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        axios.put('api/user/changePassword', {
          routeCode: 'User-14',
          user: this.state.user
        }).then(({data}) => {
          if (data.result) {
            T.toast('success', 'passwordChanged');
            this.props.closeModal();
            this.props.form.resetFields();
          } else {
            if(data.error.code === '1002'){
              T.toast('error', 'wrongOldPassword');
            }else{
              T.toast('error');
            }

            console.log(data);
          }
        }).catch(function (error) {
          T.toast('error');
          console.log(error.message);
        });
      }
    });
  };

  handleInputChange = (event) => {
    let name, value;
    if(event.target){
      const target = event.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.id;
    }else{
      value = event.value;
      name = event.name;
    }

    this.setState({
      user: { ...this.state.user,
        [name]: value
      },
      saved: false
    });

    setTimeout(() => {console.log(this.state)}, 200);
  };

  render() {
    const { intl } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loadingModal } = this.state;

    return (
      <Modal
        width={500}
        visible={this.props.modal}
        title={<IntlMessages id="profile.changePassword"/>}
        onOk={this.handleModalOk}
        onCancel={() => {
          this.props.form.resetFields();
          this.props.closeModal();
        }}
        footer={[
          <Button key="back" onClick={() => {
            this.props.form.resetFields();
            this.props.closeModal();
          }}><IntlMessages id="common.cancel"/></Button>,
          <Button key="submit" type="primary" loading={loadingModal} onClick={this.handleModalOk}>
            <IntlMessages id="common.save"/>
          </Button>,
        ]}
      >
        <Form >
          <Row>
            <Col xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={<IntlMessages id="profile.oldPassword"/>}
              >
                {getFieldDecorator('oldPassword', {
                  rules: [{
                    required: true, message: intl.formatMessage({id:'validation.requiredPassword'}),
                  }],
                })(
                  <Input type="password" onChange={this.handleInputChange}/>
                )}
              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={<IntlMessages id="profile.newPassword"/>}
              >
                {getFieldDecorator('newPassword', {
                  rules: [{
                    required: true, message: intl.formatMessage({id:'validation.requiredPassword'}),
                  }, {
                    validator: this.validateToNextPassword,
                  }],
                })(
                  <Input type="password" onChange={this.handleInputChange}/>
                )}
              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={<IntlMessages id="profile.confirmNewPassword"/>}
              >
                {getFieldDecorator('confirmNewPassword', {
                  rules: [{
                    required: true, message: intl.formatMessage({id:'validation.requiredPassword'}),
                  }, {
                    validator: this.compareToFirstPassword,
                  }],
                })(
                  <Input type="password" onChange={this.handleInputChange}/>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    )

  }
}
const ChangePasswordForm = Form.create()(injectIntl(ChangePassword));

const mapStateToProps = ({auth, settings}) => {
  const {token, user} = auth;
  const {locale} = settings;
  return {locale, token, user}
};

export default connect(mapStateToProps, {userSignOut})(ChangePasswordForm);
