import React from "react";
import {Breadcrumb} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import IntlMessages from "util/IntlMessages";

class MyBreadcrumb extends React.Component {
  routes = () => {
    let routes = [
      {
        path: '/',
        breadcrumbName: 'home',
      }
    ];
    const pathname = this.props.pathname;
    const pathSnippets = pathname.split('/').filter(i => Number.isInteger(parseInt(i)) ? null : i);
    pathSnippets.map((path) => {
      routes.push({
        path: path,
        breadcrumbName: path
      })
    });
    return routes
  };

  itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span><IntlMessages id={'breadcrumb.'+route.breadcrumbName}/></span>
    ) : (
      <Link to={'/'+(paths.join('/') === '' ? 'home' : paths.join('/'))}><span className="gx-link"><IntlMessages id={'breadcrumb.'+route.breadcrumbName}/></span></Link>
    );
  };

  render() {
    return (
        <Breadcrumb itemRender={this.itemRender} routes={this.routes()} />
    );
  }
}

const mapStateToProps = ({routing}) => {
  const {pathname} = routing.location;
  return {pathname}
};

export default connect(mapStateToProps)(MyBreadcrumb);
