import React, {Component} from "react";
import {connect} from "react-redux";
import axios from 'util/Api'
import {Avatar, Button, Col, DatePicker, Form, Input, Modal, Popover, Row, Select} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import { apiUrl } from 'util/config'
import {Link} from "react-router-dom";

import ChangePassword from "components/ChangePassword";

const FormItem = Form.Item;

class UserInfo extends Component {
  state = {
    changePassModal: false,
  };


  handleModalCancel = () => {
    this.setState({ changePassModal: false });
  };

  render() {

    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li>{this.props.user.fullName}</li>
        <li><Link to={'/profile/career'} style={{color: "inherit"}}><IntlMessages id="profile.myAccount"/></Link></li>
        <li onClick={() => this.setState({changePassModal: true})}><IntlMessages id="profile.changePassword"/></li>
        <li onClick={() => this.props.userSignOut()}><IntlMessages id="common.logout"/>
        </li>
      </ul>
    );

    return ( <>
        <ChangePassword modal={this.state.changePassModal} closeModal={this.handleModalCancel} />
        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar src={this.props.user.photo ? apiUrl+this.props.user.photo : require("assets/images/icons/avatar.svg")} className="gx-avatar gx-pointer" alt=""/>
        </Popover>
      </>
    )

  }
}

const mapStateToProps = ({auth, settings}) => {
  const {token, user} = auth;
  const {locale} = settings;
  return {locale, token, user}
};

export default connect(mapStateToProps, {userSignOut})(UserInfo);
