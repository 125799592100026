import React from "react";
import axios from 'util/Api'
import IntlMessages from "util/IntlMessages";
import {
  Card, Form, Input, Dropdown, Menu, Button, List,
  Popconfirm, Modal, Row, Col,
} from "antd";
import PageHeader from "components/PageHeader";
import NavigationTop from "../NavigationTop";
import { injectIntl } from "react-intl";
import {T, B, columnCreator} from "../../../helpers";
import moment from "moment";
import { NotificationContainer } from "react-notifications";
import {connect} from "react-redux";


const FormItem = Form.Item;
const TextArea = Input.TextArea;

const defaultAssetCategory = {
  companyId: '',
  systemDefault: false,
  name: ''
};

class SettingsAssetCategories extends React.Component {
  state = {
    assetCategories: [],
    assetCategory: defaultAssetCategory,
    addModal: false,
    editModal: false,
    loadingModal: false,
  };

  componentWillMount() {
    this.getAssetCategories();
  }

  getAssetCategories = () => {
    const { id } = this.props.company;

    axios.get('api/assetCategory/getAll/'+id, {
      params: {
        routeCode: 'AssetCategory-1'
      }
    }).then(({data}) => {
      if (data.result) {
        console.log(data);
        this.setState({
          assetCategories: data.assetCategories
        });
      } else {
        console.log(data);
      }
    }).catch(function (error) {
      console.log(error.message);
    });
  };

  handleAssetCategoryAdd = () => {
    this.props.form.resetFields();
    this.setState({
      assetCategory: defaultAssetCategory,
      addModal: true
    });
  };

  handleAssetCategoryEdit = (assetCategory) => {
    this.props.form.resetFields();
    this.setState({assetCategory, editModal: true});
  };

  handleAssetCategoryDelete = (id) => {
    this.setState({ loadingTable: true });
    axios.delete('api/assetCategory/'+id, {
      routeCode: 'AssetCategory-4'
    }).then(({data}) => {
      this.setState({ loadingTable: false });
      if (data.result) {
        this.getAssetCategories();
        this.setState({ editModal: false });
        T.toast('success', 'assetCategoryDeleted');
      } else {
        T.toast('error');
      }
    }).catch((error) => {
      T.toast('error');
    });
  };

  handleModalOk = () => {
    this.setState({loadingModal: true});
    const companyId = this.props.company.id;
    if(this.state.addModal){
      axios.post('api/assetCategory/'+companyId, {
        routeCode: 'AssetCategory-2',
        category: this.state.assetCategory
      }).then(({data}) => {
        this.setState({ loadingModal: false });
        if (data.result) {
          console.log(data);
          this.getAssetCategories();
          this.setState({ addModal: false });
          T.toast('success', 'assetCategoryAdded');
        } else {
          console.log(data);
          T.toast('error');
        }
      }).catch((error) => {
        this.setState({ loadingModal: false });
        console.log(error.message);
        T.toast('error');
      });

    } else {
      axios.put('api/assetCategory', {
        routeCode: 'AssetCategory-2',
        category: this.state.assetCategory
      }).then(({data}) => {
        this.setState({ loadingModal: false });
        if (data.result) {
          console.log(data);
          this.getAssetCategories();
          this.setState({ editModal: false });
          T.toast('success', 'assetCategoryUpdated');
        } else {
          console.log(data);
          T.toast('error');
        }
      }).catch((error) => {
        this.setState({ loadingModal: false });
        console.log(error.message);
        T.toast('error');
      });
    }
  };

  handleModalCancel = () => {
    this.setState({
      loadingModal: false,
      addModal: false,
      editModal: false,
      addCategory: false
    });
  };

  handleInputChange = (event, state = 'assetCategory') => {

    if(Array.isArray(event)){
      const inputs = {};
      event.map((input) => {
        inputs[input.name] = input.value;
      });

      this.setState({
        [state]: { ...this.state[state], ...inputs}
      });

    }else {
      let name, value;
      if(event.target){
        const target = event.target;
        value = target.type === 'checkbox' ? target.checked : target.value;
        name = target.id;
      }else{
        value = event.value;
        name = event.name;
      }
      this.setState({
        [state]: { ...this.state[state],
          [name]: value,
          systemDefault: name === 'name' ? false : this.state[state].systemDefault
        }
      });
    }

    setTimeout(() => {console.log(this.state)}, 200);
  };

  render(){
    /////////////////////////// VARRIABLE SETTINGS ////////////////////////////
    const { getFieldDecorator } = this.props.form;
    const { intl } = this.props;
    const { assetCategories, addModal, editModal, loadingModal} = this.state;
    const { systemDefault, name } = this.state.assetCategory;

    
    ///////////////////////////  DROP MENU SETTINGS //////////////////////
    const dropMenuButton = (record) => {
      return (
        <Dropdown overlay={dropMenu(record)} trigger={['click']} onClick={event => {event.stopPropagation()}}>
          <span className="gx-link ant-dropdown-link">
            <i className="icon icon-spin"/>
          </span>
        </Dropdown>
      )
    };

    const dropMenu = (record) => (
      <Menu>
        <Menu.Item key="0" onClick={() => { this.handleAssetCategoryEdit(record) }}>
          <IntlMessages id="common.edit"/>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item key="3">
          <Popconfirm title={<IntlMessages id="common.areYouSure"/>} onConfirm={() => { this.handleAssetCategoryDelete(record.id) }}
                      okText={<IntlMessages id="common.yes"/>}
                      cancelText={<IntlMessages id="common.no"/>}>
            <div style={{width:'100%'}}><IntlMessages id="common.delete"/></div>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
    
    return (
      <div>
        <PageHeader icon="settings.svg" title='settings' />
        <NavigationTop route='asset'/>
        <NotificationContainer/>
        <Modal
          width={650}
          visible={addModal !== false || editModal !== false }
          title={addModal ? <IntlMessages id={"employees.addNew"}/> : <IntlMessages id={"rules.editAssetCategory"}/> }
          onOk={this.handleModalOk}
          onCancel={this.handleModalCancel}
          footer={[
            <Button key="back" onClick={this.handleModalCancel}><IntlMessages id="common.cancel"/></Button>,
            <Button key="submit" type="primary" loading={loadingModal} onClick={this.handleModalOk}>
              <IntlMessages id="common.save"/>
            </Button>,
          ]}
        >
          <Form >
            <Row>
              <Col xs={24}>
                <FormItem
                  {...B.formItemLayout}
                  label={<IntlMessages id="rules.categoryName"/>}
                >
                  {getFieldDecorator('name', {
                    initialValue: systemDefault ? intl.formatMessage({id:'assets.'+name}) : name,
                  })(
                    <Input onChange={this.handleInputChange}/>
                  )}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Row>
          <Col xs={24}>
            <Card title={<IntlMessages id="rules.assetCategories"/>} extra={<Button type="primary" onClick={this.handleAssetCategoryAdd}><IntlMessages id="employees.addNew"/></Button>}>
              <List
                itemLayout="horizontal"
                dataSource={assetCategories}
                renderItem={item => (
                  <List.Item actions={[dropMenuButton(item)]}>
                    <List.Item.Meta
                      title={item.systemDefault ? <IntlMessages id={"assets."+item.name}/> : item.name }
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  };
}

const Injected = injectIntl(SettingsAssetCategories);
const SettingsAssetCategoriesForm = Form.create()(Injected);

const mapStateToProps = ({auth}) => {
  const {token, company} = auth;
  return {token, company}
};

export default connect(mapStateToProps)(SettingsAssetCategoriesForm);

