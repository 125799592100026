import React from "react";
import IntlMessages from "util/IntlMessages";
import {NotificationManager} from "react-notifications";
import moment from "moment";
import AppLocale from "../lngProvider";
const locale = JSON.parse(localStorage.getItem('language'));
const lng = locale && AppLocale[locale.locale].messages;

export function range(start, stop, step) {
  if (typeof stop == 'undefined') {
    // one param defined
    stop = start;
    start = 0;
  }

  if (typeof step == 'undefined') {
    step = 1;
  }

  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return [];
  }

  let result = [];
  for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }

  return result;
}

export function moneyFormatter(value) {
  let a;
  const string = `${value}`;

  if(string.indexOf('.') === string.length-1){
    a = string.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }else if(string.indexOf('.') === -1){
    a = `${parseFloat(string)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }else if(string.indexOf('.') === string.length-2){
    a = parseFloat(string).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }else if(string.indexOf('.') < string.length-2){
    a = parseFloat(string).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return a;
}

export function currencyConverter(n, locale) {
  return new Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(n);
}

export function reverse(str){
  let stringRev ="";
  for(let i= 0; i<str.length; i++){
    stringRev = str[i]+stringRev;
  }
  return stringRev;
}

export function capitalizeFirstLetter(string) {
  return `${string}`.charAt(0).toUpperCase() + string.slice(1);
}

export function toast(type, message = '') {
  if (type === 'error'){
    if(message === ''){
      return NotificationManager.error(<IntlMessages id="toast.errorDescription"/>,
        <IntlMessages id="toast.errorMessage"/>);
    }else{
      return NotificationManager.error(<IntlMessages id={"toast."+message}/>,
        <IntlMessages id="toast.errorMessage"/>);
    }

  } else if (type === 'success') {
    return NotificationManager.success(<IntlMessages id={"toast."+message}/>,
      <IntlMessages id="toast.successMessage"/>);
  }
}

export function cookie(variable, value = '') {
  if (value){
    localStorage.setItem(variable, JSON.stringify(value));
  } else {
    return JSON.parse(localStorage.getItem(variable));
  }
}

export function random(digit) {
  return Math.floor(Math.random() * Math.pow(10, digit))
}

export function timeDiff(earlierDate, olderDate) {
  const passedTime = moment.duration(olderDate.diff(earlierDate));
  const years = passedTime.years();
  const months = passedTime.months();
  const days = passedTime.days();

  const dateStr = [];
  years && dateStr.push(years+' '+lng['dateTime.years']);
  months && dateStr.push(months+' '+lng['dateTime.months']);
  days && dateStr.push(days+' '+lng['dateTime.days']);

  return dateStr.join(' ')
}

export function dateRange(earlierDate, olderDate) {

  const day = moment(earlierDate).format('D ');
  const month = moment(earlierDate).isSame(olderDate, 'month') ? '' : moment(earlierDate).format(' MMMM ');
  const year = moment(earlierDate).isSame(olderDate, 'year') ? '' : moment(earlierDate).format(' YYYY ');
  const secondDate = moment(olderDate).format('- D MMMM YYYY');

  return day+month+year+secondDate;
}

export function sef(text) {
  const trMap = {
    'çÇ':'c',
    'ğĞ':'g',
    'şŞ':'s',
    'üÜ':'u',
    'ıİ':'i',
    'öÖ':'o'
  };
  for(let key in trMap) {
    text = text.replace(new RegExp('['+key+']','g'), trMap[key]);
  }
  return  text.replace(/[^-_.a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
              .replace(/\./gi, "-") // convert dots to dashes
              .replace(/_/gi, "-") // convert underscores to dashes
              .replace(/\s/gi, "-") // convert spaces to dashes
              .replace(/[-]+/gi, "-") // trim repeated dashes
              .toLowerCase();

}
