import ReactDOM from "react-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import React from "react";
import AppLocale from "../lngProvider";

export default function getUserConfirmation (message, callback) {

  const modal = document.createElement('div');
  document.body.appendChild(modal);

  const withCleanup = (answer) => {
    ReactDOM.unmountComponentAtNode(modal);
    document.body.removeChild(modal);
    callback(answer)
  };

  const locale = JSON.parse(localStorage.getItem('language'));
  const lng = AppLocale[locale.locale].messages;

  ReactDOM.render(
    <SweetAlert
      warning
      showCancel
      confirmBtnText={lng['common.confirm']}
      cancelBtnText={lng['common.cancel']}
      confirmBtnBsStyle="warning"
      title={lng['sweetAlert.warning']}
      onConfirm={() => withCleanup(true)}
      onCancel={() => withCleanup(false)}
    >{lng[message]}
    </SweetAlert>
    ,
    modal
  )
}

