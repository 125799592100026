export const formItemLayout = {
  labelAlign: 'left',
  colon: false,
  required: false,
  labelCol: {
    xs: {span: 24},
  },
  wrapperCol: {
    xs: {span: 24},
  },
};

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export const tableSettings = {
  bordered: false,
  pagination: { position: 'bottom' },
  size: 'default',
  showHeader: true,
  rowKey: "id",
};

export const dateFormat = 'YYYY-MM-DD HH:mm:ss';

export const currencies = [
  "TL",
  "USD",
  "EUR",
  "AZN",
  "GBP",
  "SGD",
  "JPY",
  "CNH",
  "KRW",
  "AUD",
  "AED",
  "RUB",
  "PLN",
  "TWD",
  "UAH",
  "VND",
  "THB",
  "INR",
  "CZK",
  "PHP",
  "MYR"
];
