import React from "react";
import {Card, Col} from "antd";

import MyBreadcrumb from "components/Breadcrumb";

import IntlMessages from "util/IntlMessages";

class PageHeader extends React.Component {

  render() {
    return (
      <Col style={{padding:0}} lg={24} md={24} sm={24} xs={0}>
      <Card className="gx-card gx-mb-4">
        <div className={"gx-user-list gx-pb-0"} style={{alignItems: "center"}}>
          <img src={require("assets/images/icons/"+this.props.icon)} className="gx-avatar-img gx-border-0"/>
          <div className="gx-description" style={{width:"calc(100% - 400px)"}}>
            <div className="gx-flex-row">
              <h3>{this.props.specialTitle || this.props.specialTitle === '' ? this.props.specialTitle : <IntlMessages id={this.props.title}/>}</h3>
            </div>
            {
              this.props.description ?<p className="gx-text-grey gx-mb-0"><IntlMessages id={this.props.description}/></p> :null
            }

          </div>
          <div className="gx-card-list-footer" style={{marginLeft:"auto"}} >
            <MyBreadcrumb/>
          </div>
        </div>
      </Card>
      </Col>
    );
  }
}

export default PageHeader;
