import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu, Icon, Button} from "antd";
import {Link} from "react-router-dom";
import jwt from "jsonwebtoken";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import CompanyName from "./CompanyName";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR, TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const {themeType, navStyle, pathname, navCollapsed} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    const {accessType, permission} = jwt.decode(this.props.token);

    const allow = (accessType === 1 || accessType === 2) && permission;


    return (<Auxiliary>

        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <CompanyName/>
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">
              <Menu.Item key="home">
                <Link to="/home"><i className="icon icon-widgets"/>
                  <IntlMessages id="sidebar.home"/></Link>
              </Menu.Item>
              {permission &&
              <Menu.Item key="employees">
                <Link to="/employees"><Icon type="team" style={{fontSize: 20}} />
                  <IntlMessages id="sidebar.employees"/></Link>
              </Menu.Item>
              }

              {allow &&
                <Menu.Item key="calendar">
                  <Link to="/calendar"><i className="icon icon-calendar-new"/>
                    <IntlMessages id="sidebar.calendar"/></Link>
                </Menu.Item>
              }
              {allow &&
                <Menu.Item key="reports">
                  <Link to="/reports"><i className="icon icon-chart"/>
                    <IntlMessages id="sidebar.reports"/></Link>
                </Menu.Item>
              }
              {allow &&
                <Menu.Item key="leaves">
                  <Link to="/leaves"><i className="icon icon-select"/>
                    <IntlMessages id="sidebar.leaves"/></Link>
                </Menu.Item>
              }
              {allow &&
                <Menu.Item key="settings">
                  <Link to="/settings/system-settings"><Icon type="setting" theme="filled" />
                    <IntlMessages id="sidebar.settings"/></Link>
                </Menu.Item>
              }
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings, auth}) => {
  const {navStyle, themeType, locale, navCollapsed, pathname} = settings;
  const {token} = auth;
  return {navStyle, themeType, locale, navCollapsed, pathname, token}
};
export default connect(mapStateToProps)(SidebarContent);

