import React from "react";
import {Link} from "react-router-dom";
import axios from 'util/Api'
import { apiUrl } from 'util/config'
import IntlMessages from "util/IntlMessages";
import {Button, Dropdown, Menu} from "antd";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlaneDeparture, faMoneyBillWave, faMoneyCheck, faClock, faFileAlt } from '@fortawesome/free-solid-svg-icons'

import LeaveRequest from "./LeaveRequest";
import OvertimeRequest from "./OvertimeRequest";
import AllowanceRequest from "./AllowanceRequest";
import ExpenseRequest from "./ExpenseRequest";
import VisaDocumentRequest from "./VisaDocumentRequest";


class Requests extends React.Component {


  acceptMethods = (acceptedMethod,funcName) => {

    switch (funcName) {
      case 'openLeaveModal':
        this.openLeaveModal = acceptedMethod;
        break;
      case 'openAllowanceModal':
        this.openAllowanceModal = acceptedMethod;
        break;
      case 'openExpenseModal':
        this.openExpenseModal = acceptedMethod;
        break;
      case 'openOvertimeModal':
        this.openOvertimeModal = acceptedMethod;
        break;
      case 'openVisaDocumentModal':
        this.openVisaDocumentModal = acceptedMethod;
        break;
      default:
    }
  };

  render(){
    const intl = this.props.intl;

    const menu = (
      <Menu>
        <Menu.Item onClick={() => { this.openLeaveModal() }}>
          <FontAwesomeIcon icon={faPlaneDeparture} /> &nbsp;&nbsp; <IntlMessages id="breadcrumb.timeOff"/>
        </Menu.Item>
        <Menu.Item onClick={() => { this.openAllowanceModal() }}>
          <FontAwesomeIcon icon={faMoneyBillWave} /> &nbsp;&nbsp;<IntlMessages id="employees.allowance"/>
        </Menu.Item>
        <Menu.Item onClick={() => { this.openExpenseModal() }}>
          <FontAwesomeIcon icon={faMoneyCheck} /> &nbsp;&nbsp;<IntlMessages id="employees.expense"/>
        </Menu.Item>
        <Menu.Item onClick={() => { this.openOvertimeModal() }}>
          <FontAwesomeIcon icon={faClock} /> &nbsp;&nbsp;<IntlMessages id="employees.overtime"/>
        </Menu.Item>
        <Menu.Item onClick={() => { this.openVisaDocumentModal() }}>
          <FontAwesomeIcon icon={faFileAlt} /> &nbsp;&nbsp;<IntlMessages id="employees.visaDocument"/>
        </Menu.Item>
      </Menu>
    );


    return (
      <div>
        <LeaveRequest  shareMethods={this.acceptMethods} />
        <OvertimeRequest shareMethods={this.acceptMethods} />
        <AllowanceRequest shareMethods={this.acceptMethods} />
        <ExpenseRequest shareMethods={this.acceptMethods} />
        <VisaDocumentRequest shareMethods={this.acceptMethods} />
        <Dropdown overlay={menu} trigger={['click']}>
          <Button type="primary" ghost style={{marginTop:10}}><IntlMessages id="common.request"/></Button>
        </Dropdown>
      </div>
    );
  };
}


const Injected = injectIntl(Requests);

const mapStateToProps = ({auth, settings}) => {
  const {token, company} = auth;
  const {locale} = settings;
  return {locale,token, company}
};


export default connect(mapStateToProps)(Injected);
