import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  COMPANY_INFO_SET
} from "../../constants/ActionTypes";
import axios from 'util/Api'
import AppLocale from "lngProvider";
import {cookie} from "../../helpers/tools"

const getApiMessage = (code) => {
  let language = cookie('language');
  const lng = AppLocale[language.locale].messages;
  return lng.api_messages[code];
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = (formValues) => {

  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('register', formValues).then(({data}) => {

      if (data.result) {

       /* cookie('token', data.token);
        cookie('userId', data.userId);
        cookie('user', data.user);
        cookie('company', data.company);
        axios.defaults.headers.common['x-access-token'] = data.token;*/
        dispatch({type: FETCH_SUCCESS});
        /*dispatch({type: USER_DATA, payload: data.user});
        dispatch({type: USER_TOKEN_SET, payload: data.token});
        dispatch({type: COMPANY_INFO_SET, payload: data.company});*/

        window.location.href="/"
      } else {
        if(data.message){
          dispatch({type: FETCH_ERROR, payload: data.message});
        }else{
          dispatch({type: FETCH_ERROR, payload: "Network Error"});
        }

      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({email, password}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('authenticate', {
        email: email,
        password: password,
      }
    ).then(({data}) => {
      if (data.result) {
        console.log(data);
        cookie('token', data.token);
        cookie('userId', data.userId);
        cookie('user', data.user);
        cookie('company', data.company);
        axios.defaults.headers.common['x-access-token'] = data.token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.user});
        dispatch({type: COMPANY_INFO_SET, payload: data.company});
        dispatch({type: USER_TOKEN_SET, payload: data.token});
        window.location.reload()
      } else {
        dispatch({type: FETCH_ERROR, payload: getApiMessage(data.error.code)});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.get('api/user/getToken', {
      params: {
          routeCode: 'User-1',
        }
      }
    ).then(({data}) => {
      if (!data.result || !data.user || !data.company) {
        localStorage.removeItem("token");
        axios.defaults.headers.common['x-access-token'] = '';
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SIGNOUT_USER_SUCCESS});
      }
      //dispatch({type: USER_TOKEN_SET, payload: data.token});
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    setTimeout(() => {
      localStorage.removeItem("token");
      axios.defaults.headers.common['x-access-token'] = '';
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: SIGNOUT_USER_SUCCESS});
      window.location.reload()
    }, 10);
  }
};
