const languageData = [
  {
    languageId: 'turkish',
    locale: 'tr',
    name: 'Türkçe',
    icon: 'tr'
  },{
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  }
];
export default languageData;
