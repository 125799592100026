import React from "react";
import {Button, Checkbox, Form, Input, Layout, Row, Col} from "antd";
import {Link} from "react-router-dom";
import axios from 'util/Api'
import {connect} from "react-redux";
import {userSignUp} from "../appRedux/actions/Auth";
import {injectIntl} from 'react-intl';
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import {Language} from "./Topbar/Language";
import {switchLanguage} from "../appRedux/actions";
import {T} from "../helpers";
import {NotificationContainer} from "react-notifications";

const FormItem = Form.Item;
const {Header} = Layout;

class ResetPassword extends React.Component {

  componentDidMount() {
    this.checkResetKey();
  }

  checkResetKey = () => {
    const {resetKey} = this.props.match.params;

    axios.get('checkResetKey/'+resetKey, {
      params: {
        routeCode: 'Index-4'
      }
    }).then(({data}) => {
      console.log(data);
      if (!data.result) {
        this.props.history.push('/signin');
      }
    }).catch(function (error) {
      T.toast('error');
      console.log(error.message);
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {resetKey} = this.props.match.params;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        axios.put('resetPassword', {
          routeCode: 'Index-3',
          ...values,
          resetKey
        }).then(({data}) => {
          console.log(data);
          if (data.result) {
            T.toast('success', 'passwordChanged');
            setTimeout(() => {
              this.props.history.push('/signin');
            }, 2000)
          } else {
            T.toast('error');
            console.log(data);
          }
        }).catch(function (error) {
          T.toast('error');
          console.log(error.message);
        });

      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(this.props.intl.formatMessage({id:'validation.inconsistentPasswords'}));
    } else {
      callback();
    }
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    const intl = this.props.intl;


    return (
      <div className="gx-app-login-wrap">
        <NotificationContainer/>
        <Header style={{position:'absolute',top:0,left:0,right:0}}>
          <Language switchLanguage={this.props.switchLanguage}/>
        </Header>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src={require("assets/images/bg.jpg")} alt='Neature'/>
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.resetPassword"/></h1>
                <p><IntlMessages id="app.userAuth.bySigning"/></p>
                <p><IntlMessages id="app.userAuth.getAccount"/></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/hrm.png")}/>
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} hideRequiredMark={true} className="gx-signup-form gx-form-row0">

                <FormItem
                  label={(
                  <IntlMessages id="profile.newPassword"/>
                )}>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({id:'validation.requiredPassword'})
                      }
                      ],
                  })(
                    <Input type="password"/>
                  )}
                </FormItem>

                <FormItem
                  label={(
                    <IntlMessages id="profile.confirmNewPassword"/>
                  )}>
                  {getFieldDecorator('confirm', {
                    rules: [
                      {
                        validator: this.compareToFirstPassword,
                      }
                      ],
                  })(
                    <Input type="password"/>
                  )}
                </FormItem>

                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.reset"/>
                  </Button>
                </FormItem>
              </Form>
            </div>
            <InfoView/>
          </div>
        </div>
      </div>
    );
  }
}

const ResetPasswordForm = Form.create()(injectIntl(ResetPassword));

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userSignUp, switchLanguage})(ResetPasswordForm);
