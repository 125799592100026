import React from "react";
import {Button, Checkbox, Form, Input, Layout} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {injectIntl} from 'react-intl';

import {userSignIn} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import {Language} from "./Topbar/Language";
import {switchLanguage} from "../appRedux/actions";

const FormItem = Form.Item;
const {Header} = Layout;
class SignIn extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userSignIn(values);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const intl = this.props.intl;
    return (
      <div className="gx-app-login-wrap">
        <Header style={{position:'absolute',top:0,left:0,right:0}}>
          <Language switchLanguage={this.props.switchLanguage}/>
        </Header>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src={require("assets/images/bg.jpg")} alt='Neature'/>
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signIn"/></h1>
                <p><IntlMessages id="hrmnedir"/></p>

              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/hrm.png")}/>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{
                      required: true, type: 'email', message: intl.formatMessage({id:'validation.invalidEmail'}),
                    }],
                  })(
                    <Input placeholder="email"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{required: true, message: intl.formatMessage({id:'validation.requiredPassword'})}],
                  })(
                    <Input type="password" placeholder={intl.formatMessage({id:'appModule.password'})}/>
                  )}
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn"/>
                  </Button>
                  <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signup"><IntlMessages
                  id="app.userAuth.signUp"/></Link>
                </FormItem>

              </Form>
            </div>
            <InfoView/>
          </div>
        </div>
      </div>
    );
  }
}
const InjectedForm = injectIntl(SignIn); // IntlMessages çalışmadığı zaman kullan
const WrappedNormalLoginForm = Form.create()(InjectedForm);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userSignIn, switchLanguage})(WrappedNormalLoginForm);
