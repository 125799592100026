import React from "react";
import {Link} from "react-router-dom";
import axios from 'util/Api'
import { apiUrl } from 'util/config'
import IntlMessages from "util/IntlMessages";
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Icon,
  Input, InputNumber,
  Menu, Modal,
  Radio,
  Row, Select, Switch,
  Table, TimePicker,
  TreeSelect,
} from "antd";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {B, T} from "../../helpers";
import moment from "moment";


const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;
const InputGroup = Input.Group;

const defaultOvertime = {
  cancellationReason: '',
  convertType: 'unconverted',
  description: '',
  endDate: '',
  start: '',
  startDate: '',
  startTime: '',
  rejectReason: '',
  usedMinute: null
};


class Request extends React.Component {
  state = {
    employees: [],
    overtimeTypes: [],
    modal: false,
    overtime: defaultOvertime,
    loadingModal: false
  };

  componentDidMount() {
    this.props.shareMethods(this.openOvertimeModal.bind(this),"openOvertimeModal");
    this.localizeDateTime();
  }

  openOvertimeModal() {
    this.props.form.resetFields();
    this.setState({ overtime: defaultOvertime, modal: true });
  };

  closeModal = () => {
    this.setState({modal: false});
  };

  localizeDateTime = () => {
    const intl = this.props.intl;
    const lng_code = intl.formatMessage({id:'options.dateTimeLocale'});
    import('moment/locale/'+lng_code);
  };

  handleModalOk = () => {

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loadingModal: true});
        axios.post('api/overtime/request', {
          routeCode: 'Overtime-7',
          overtime: this.state.overtime
        }).then(({data}) => {
          this.setState({ loadingModal: false });
          if (data.result) {
            console.log(data);
            T.toast('success', 'requestDone');
            this.setState({ modal: false });
          } else {
            console.log(data);
            T.toast('error');
          }
        }).catch((error) => {
          console.log(error.message);
          T.toast('error');
        });
      }
    });

  };

  handleInputChange = (event, state = 'overtime') => {

    if(Array.isArray(event)){
      const inputs = {};
      event.map((input) => {
        inputs[input.name] = input.value;
      });

      this.setState({
        [state]: { ...this.state[state], ...inputs}
      });
      setTimeout(() => {
        const { startDate, endDate } = this.state.overtime;
        const usedDays = startDate && endDate ? moment(endDate).diff(moment(startDate), 'days')+
          (((moment(endDate).unix()-moment(startDate).unix()) % 86400) >= 18000 ? 1 : 0.5) : 0;
        this.setState({overtime: {...this.state.overtime, usedDays}});
      }, 200);


    }else {
      let name, value;
      if(event.target){
        const target = event.target;
        value = target.type === 'checkbox' ? target.checked : target.value;
        name = target.id;
      }else{
        value = event.value;
        name = event.name;
      }
      this.setState({
        [state]: { ...this.state[state],
          [name]: value
        }
      });
    }

    setTimeout(() => {console.log(this.state)}, 200);
  };

  render(){
    const intl = this.props.intl;
    const dateFormat = intl.formatMessage({id:'options.dateTimeFormat'});
    const {getFieldDecorator} = this.props.form;

    const { loadingModal, overtimeTypes, modal} = this.state;

    const { cancellationReason, convertType, description, endDate, start, startTime, startDate,
      rejectReason, status, usedMinute} = this.state.overtime;
    return (
      <Modal
        width={650}
        visible={modal}
        title={<IntlMessages id="requests.requestOvertime"/>}
        onOk={this.handleModalOk}
        onCancel={this.closeModal}
        footer={[
          <Button key="back" onClick={this.closeModal}><IntlMessages id="common.cancel"/></Button>,
          <Button key="submit" type="primary" loading={loadingModal} onClick={this.handleModalOk}>
            <IntlMessages id="common.save"/>
          </Button>,
        ]}
      >
        <Form >
          <Row>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="employees.startDate"/>
                )}
              >
                <InputGroup compact>
                  {getFieldDecorator('startDate', {
                    initialValue: start.length === 19 ? moment(start) : null,
                    rules: [{type: 'object', required: true, message: <IntlMessages id="validation.selectDate"/>}],
                  })(
                    <DatePicker
                      format={dateFormat}
                      style={{width: 'calc(100% - 120px)'}}
                      onChange={(_, date) => {
                        const Date = moment(date, dateFormat).format('YYYY-MM-DD');
                        this.handleInputChange([
                            {name:'startDate', value:Date},
                            {name:'start', value: Date + ' ' + startTime}
                          ]
                        )
                      }}
                    />
                  )}
                  {getFieldDecorator('startTime', {
                    initialValue: start.length === 19 ? moment(start) : null,
                    rules: [{type: 'object', required: true, message: <IntlMessages id="validation.selectTime"/>}],
                  })(
                    <TimePicker
                      style={{width: 120}}
                      format='HH:mm'
                      onChange={(_, date) => {
                        const time = date+':00';
                        this.handleInputChange([
                            {name:'startTime', value:time},
                            {name:'start', value:startDate + ' ' + time}
                          ]
                        )
                      }}
                    />
                  )}
                </InputGroup>

              </FormItem>
            </Col>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={<>
                  <IntlMessages id="employees.duration"/> (<IntlMessages id="dateTime.hour"/>)
                </>
                }
              >
                {getFieldDecorator('duration', {
                  initialValue: usedMinute/60,
                })(
                  <InputNumber
                    style={{width: '100%'}}
                    onChange={(value) => {  this.handleInputChange({name: 'usedMinute', value: value ? parseFloat(value)*60 : 0}) }}
                    formatter={ value => value ? parseFloat(value) : 0 }/>
                )}
              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="employees.description"/>
                )}
              >
                {getFieldDecorator('description', {
                  initialValue: description,
                })(
                  <Input onChange={this.handleInputChange}/>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  };
}



const RequestForm = Form.create()(injectIntl(Request));

const mapStateToProps = ({auth, settings}) => {
  const {token, company} = auth;
  const {locale} = settings;
  return {locale, token, company}
};


export default connect(mapStateToProps)(RequestForm);
