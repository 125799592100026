import React from "react";
import {Link} from "react-router-dom";
import axios from 'util/Api'
import { apiUrl } from 'util/config'
import IntlMessages from "util/IntlMessages";
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Icon,
  Input, InputNumber,
  Menu, Modal,
  Radio,
  Row, Select, Switch,
  Table, TimePicker, Tooltip,
  TreeSelect, Upload,
} from "antd";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {B, T} from "../../helpers";
import moment from "moment";
import _ from "lodash";


const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;
const InputGroup = Input.Group;


class Request extends React.Component {

  defaultExpense = {
    affectPayroll: false,
    amount: '',
    total: 0,
    approvalDate: '',
    approvalSteps: '',
    cancellationReason: '',
    currency: this.props.intl.formatMessage({id:'options.currency'}),
    currentAmount: '',
    currentInstallment: '',
    date: '',
    description: '',
    formattedDate: '',
    installmentPlan: '',
    isGross: false,
    paid: false,
    rejectReason: '',
    type: 'expense',
    transactionItems: [],
    removedItems: []
  };

  state = {
    employees: [],
    modal: false,
    expense: this.defaultExpense,
    loadingModal: false
  };

  formData = new FormData();

  componentDidMount() {
    this.props.shareMethods(this.openExpenseModal.bind(this),"openExpenseModal");
    this.localizeDateTime();
  }

  openExpenseModal() {
    this.props.form.resetFields();
    console.log('dfsdgdfgfdg');
    this.setState({ expense: {...this.defaultExpense, transactionItems: []}, modal: true });
  };

  closeModal = () => {
    this.setState({modal: false});
  };

  localizeDateTime = () => {
    const intl = this.props.intl;
    const lng_code = intl.formatMessage({id:'options.dateTimeLocale'});
    import('moment/locale/'+lng_code);
  };

  handleModalCancel = () => {
    this.formData.delete('images');
    this.formData.delete('data');

    this.setState({ modal: false });
  };

  handleModalOk = () => {

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loadingModal: true});
        const { id } = this.props.company;
        const data = {
          routeCode: 'Transaction-8',
          transaction: this.state.expense,
        };

        this.formData.append('data', JSON.stringify(data));

        axios.post('api/transaction/expenseRequest/'+id, this.formData).then(({data}) => {

          this.setState({ loadingModal: false });
          if (data.result) {
            console.log(data);
            this.handleModalCancel();
            T.toast('success', 'requestDone');
          } else {
            console.log(data);
            this.formData.delete('data');
            T.toast('error');
          }
        }).catch((error) => {
          console.log(error.message);
          this.formData.delete('data');
          T.toast('error');
        });
      }
    });

  };

  handleAddItem = () => {
    const intl = this.props.intl;

    const defaultItem = {
      id: 'new'+T.random(5),
      transactionId: '',
      amount: '',
      description: '',
      file: '',
      localName: '',
      name: intl.formatMessage({id:'employees.receipt'}) + ' ' + (this.state.expense.transactionItems.length+1),
      unitPrice: 0,
      vatRate: 18,
    };

    const expense = _.cloneDeep(this.state.expense);
    expense.transactionItems.push(defaultItem);

    this.setState({
      expense
    });

    setTimeout(() => {console.log(this.state)}, 200);
  };

  handleItemChange = (index, name, value) => {

    let expense = _.cloneDeep(this.state.expense);
    expense.transactionItems[index][name] = value;

    if(name === 'amount'){
      let total = isNaN(value) ? 0 : value;
      this.state.expense.transactionItems.map((item, i) => { if(i !== index) total += (isNaN(item.amount) ? 0 : item.amount) } );
      expense.amount = total;
    }

    if(name === 'file'){
      expense.transactionItems[index].isFileChanged = true;
    }

    this.setState({ expense });

    setTimeout(() => {console.log(this.state)}, 200);
  };

  handleItemDelete = (record, index) => {

    const expense = _.cloneDeep(this.state.expense);
    expense.transactionItems = expense.transactionItems.filter((item, i) => i !== index);


    let total = 0;
    expense.transactionItems.map((item) => { total += item.amount });

    this.removeFromItemFiles(record.file);

    this.setState({ expense });

    setTimeout(() => {console.log(this.state)}, 200);
  };

  removeFromItemFiles = (fileName) => {
    const filteredFiles = this.formData.getAll('images').filter(file => file.name !== fileName);
    this.formData.delete('images');
    filteredFiles.map((file) => {
      this.formData.append('images', file);
    });
  };

  customRequest = (options, index) => {
    const fileName = options.file.name;
    const ext = fileName.split('.').pop();
    const newName = T.random(11)+'.'+ext;

    this.formData.append('images', options.file, newName);
    this.handleItemChange(index, 'file', newName);
    this.handleItemChange(index, 'localName', fileName);
  };

  handleInputChange = (event, state = 'expense') => {

    if(Array.isArray(event)){
      const inputs = {};
      event.map((input) => {
        inputs[input.name] = input.value;
      });

      this.setState({
        [state]: { ...this.state[state], ...inputs}
      });

      setTimeout(() => {
        const { startDate, endDate } = this.state.expense;
        const usedDays = startDate && endDate ? moment(endDate).diff(moment(startDate), 'days')+
          (((moment(endDate).unix()-moment(startDate).unix()) % 86400) >= 18000 ? 1 : 0.5) : 0;
        this.setState({expense: {...this.state.expense, usedDays}});
      }, 200);


    }else {
      let name, value;
      if(event.target){
        const target = event.target;
        value = target.type === 'checkbox' ? target.checked : target.value;
        name = target.id;
      }else{
        value = event.value;
        name = event.name;
      }
      this.setState({
        [state]: { ...this.state[state],
          [name]: value
        }
      });
    }

    setTimeout(() => {console.log(this.state)}, 200);
  };

  render(){
    /////////////////////////// VARRIABLE SETTINGS ////////////////////////////

    const intl = this.props.intl;
    const dateFormat = intl.formatMessage({id:'options.dateTimeFormat'});
    const {getFieldDecorator} = this.props.form;

    const { loadingModal, modal} = this.state;

    const { amount, date, description, installmentPlan, transactionItems, paid} = this.state.expense;

    ///////////////////////////  TABLE DROP MENU SETTINGS //////////////////////
    const expenseMenu = (record, index) =>  (
      <Menu>

        <Menu.Item key="0" onClick={() => { this.handleItemDelete(record, index) }}>
          <IntlMessages id="employees.deleteReceipt"/>
        </Menu.Item>
        {
          record.file ?
            <Menu.Item key="1" onClick={() => {
              this.removeFromItemFiles(record.file);
              this.handleItemChange(index, 'file', '');
            }}>
              <IntlMessages id="employees.deleteFile"/>
            </Menu.Item>
            : null
        }
      </Menu>
    );

    return (
      <Modal
        width={650}
        visible={modal}
        title={<IntlMessages id="requests.requestExpense"/>}
        onOk={this.handleModalOk}
        onCancel={this.closeModal}
        footer={[
          <Button key="back" onClick={this.closeModal}><IntlMessages id="common.cancel"/></Button>,
          <Button key="submit" type="primary" loading={loadingModal} onClick={this.handleModalOk}>
            <IntlMessages id="common.save"/>
          </Button>,
        ]}
      >
        <Form >
          <Row>
            <Col xs={24}>
              <Button style={{width: '100%'}} onClick={this.handleAddItem}><IntlMessages id={"employees.addInvoice"}/></Button>
            </Col>
            {
              transactionItems.map((item, index) => <React.Fragment key={index}>
                <Col xs={8}>
                  <FormItem {...B.formItemLayout}>
                    {getFieldDecorator('name'+item.id, {
                      initialValue: item.name,
                    })(
                      <Input placeholder={intl.formatMessage({id:'employees.invoiceName'})}
                             onChange={(e) => { this.handleItemChange(index, 'name', e.target.value) }}/>
                    )}
                  </FormItem>
                </Col>
                <Col xs={7}>
                  <FormItem {...B.formItemLayout}>
                    {getFieldDecorator('amount'+item.id, {
                      initialValue: item.amount,
                      rules: [{required: true, message: intl.formatMessage({id:'validation.required'})}],
                    })(
                      <InputNumber
                        style={{width: '100%'}}
                        placeholder={intl.formatMessage({id:'employees.amount'})}
                        onChange={(value) => { this.handleItemChange(index, 'amount', parseFloat(value)) }}
                        formatter={value => T.moneyFormatter(value)}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col xs={5}>
                  {getFieldDecorator('vatRate'+item.id, {
                    initialValue: item.vatRate
                  })(
                    <Select onChange={(value) => { this.handleItemChange(index, 'vatRate', value) }} >
                      <Option key={1} value={0}>0%</Option>
                      <Option key={2} value={1}>1%</Option>
                      <Option key={3} value={6}>6%</Option>
                      <Option key={4} value={8}>8%</Option>
                      <Option key={5} value={10}>10%</Option>
                      <Option key={6} value={13}>13%</Option>
                      <Option key={7} value={18}>18%</Option>
                      <Option key={8} value={24}>24%</Option>
                    </Select>
                  )}
                </Col>
                <Col xs={4}>
                  {
                    item.file ?
                      <Tooltip title={item.localName}>
                        <i className="icon icon-editor" style={{lineHeight: '40px', fontSize:20, marginRight: 15}}/>
                      </Tooltip>
                      :
                      <Upload name="file"
                              multiple={false}
                              showUploadList={false}
                              customRequest={(option) => { this.customRequest(option, index) }}>
                        <i className="icon icon-upload" style={{lineHeight: '40px', fontSize:20, marginRight: 15, cursor: 'pointer'}}/>
                      </Upload>
                  }


                  <span className="gx-link ant-dropdown-link" style={{lineHeight: '40px', fontSize:17}}>
                      <Dropdown overlay={expenseMenu(item, index)} trigger={['click']}>
                        <span className="gx-link ant-dropdown-link">
                          <i className="icon icon-spin"/>
                        </span>
                      </Dropdown>
                    </span>
                </Col>
              </React.Fragment> )
            }
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={<IntlMessages id="employees.amount"/>}
              >
                <InputGroup compact>
                  <InputNumber
                    style={{width: 'calc(100% - 80px)'}}
                    value={amount}
                    onChange={(value) => { this.handleInputChange({name: 'amount', value: parseFloat(value)}) }}
                    formatter={value => T.moneyFormatter(value)}
                  />

                  <Select defaultValue={intl.formatMessage({id:'options.currency'})}
                          style={{width: 80}}
                          onChange={(value) => { this.handleInputChange({name: 'currency', value}) }}>
                    {B.currencies.map((cur) =>  <Option key={cur} value={cur}>{cur}</Option> )}
                  </Select>
                </InputGroup>

              </FormItem>
            </Col>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="employees.date"/>
                )}
              >
                {getFieldDecorator('date', {
                  initialValue: date ? moment(date) : null,
                  rules: [{type: 'object', required: true, message: <IntlMessages id="validation.selectDate"/>}],
                })(
                  <DatePicker
                    className="gx-mb-3 gx-w-100"
                    format={dateFormat}
                    onChange={(_, date) => { this.handleInputChange({name:'date', value: moment(date, dateFormat).format(B.dateFormat)}) }}
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="employees.description"/>
                )}
              >
                {getFieldDecorator('description', {
                  initialValue: description,
                })(
                  <Input onChange={this.handleInputChange}/>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  };
}



const RequestForm = Form.create()(injectIntl(Request));

const mapStateToProps = ({auth, settings}) => {
  const {token, company} = auth;
  const {locale} = settings;
  return {locale, token, company}
};


export default connect(mapStateToProps)(RequestForm);
