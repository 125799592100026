import React from "react";
import {Link} from "react-router-dom";
import axios from 'util/Api'
import { apiUrl } from 'util/config'
import IntlMessages from "util/IntlMessages";
import {Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, TimePicker} from "antd";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {B, T} from "../../helpers";
import moment from "moment";


const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;
const InputGroup = Input.Group;

const defaultLeave = {
  cancellationReason: '',
  comment: '',
  nextApprovalPerson: '',
  replacementPersonId: '',
  leaveTypeId: '',
  endDate: '',
  endDay: '',
  endTime: '',
  returnDate: '',
  startDate: '',
  startDay: '',
  startTime: '',
  usedDays: ''
};


class Request extends React.Component {
  state = {
    employees: [],
    leaveTypes: [],
    modal: false,
    leave: defaultLeave,
    loadingModal: false
  };

  componentDidMount() {
    this.props.shareMethods(this.openLeaveModal.bind(this),"openLeaveModal");
    this.localizeDateTime();
    this.getLeaveTypes();
  }

  openLeaveModal() {
    this.props.form.resetFields();
    this.setState({ leave: defaultLeave, modal: true });
  };

  closeModal = () => {
    this.setState({modal: false});
  };

  localizeDateTime = () => {
    const intl = this.props.intl;
    const lng_code = intl.formatMessage({id:'options.dateTimeLocale'});
    import('moment/locale/'+lng_code);
  };

  getLeaveTypes = () => {
    const { id } = this.props.company;
    const { token } = this.props;
    axios.get('api/leaveType/getAll/'+id, {
      params: {
        routeCode: 'LeaveType-1'
      },
      headers: {
        'x-access-token': token
      }
    }).then(({data}) => {
      if (data.result) {
        console.log(data);
        this.setState({
          leaveTypes: data.leaveTypes
        });
      } else {
        console.log(data);
      }
    }).catch(function (error) {
      console.log(error.message);
    });
  };

  handleModalOk = () => {

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loadingModal: true});
        axios.post('api/leave/request', {
          routeCode: 'Leave-16',
          leave: this.state.leave
        }).then(({data}) => {
          this.setState({ loadingModal: false });
          if (data.result) {
            console.log(data);
            T.toast('success', 'requestDone');
            this.setState({ modal: false });
          } else {
            console.log(data);
            T.toast('error');
          }
        }).catch((error) => {
          console.log(error.message);
          T.toast('error');
        });
      }
    });

  };

  handleInputChange = (event, state = 'leave') => {

    if(Array.isArray(event)){
      const inputs = {};
      event.map((input) => {
        inputs[input.name] = input.value;
      });

      this.setState({
        [state]: { ...this.state[state], ...inputs}
      });
      setTimeout(() => {
        const { startDate, endDate } = this.state.leave;
        const usedDays = startDate && endDate ? moment(endDate).diff(moment(startDate), 'days')+
          (((moment(endDate).unix()-moment(startDate).unix()) % 86400) >= 18000 ? 1 : 0.5) : 0;
        this.setState({leave: {...this.state.leave, usedDays}});
      }, 200);


    }else {
      let name, value;
      if(event.target){
        const target = event.target;
        value = target.type === 'checkbox' ? target.checked : target.value;
        name = target.id;
      }else{
        value = event.value;
        name = event.name;
      }
      this.setState({
        [state]: { ...this.state[state],
          [name]: value
        }
      });
    }

    setTimeout(() => {console.log(this.state)}, 200);
  };

  render(){
    const intl = this.props.intl;
    const dateFormat = intl.formatMessage({id:'options.dateTimeFormat'});
    const {getFieldDecorator} = this.props.form;

    const { loadingModal, leaveTypes, modal} = this.state;

    const { comment, startDate, startDay, startTime, endDate, endDay, endTime, leaveTypeId, usedDays} = this.state.leave;

    return (
      <Modal
        width={650}
        visible={modal}
        title={<IntlMessages id="requests.requestTimeOff"/>}
        onOk={this.handleModalOk}
        onCancel={this.closeModal}
        footer={[
          <Button key="back" onClick={this.closeModal}><IntlMessages id="common.cancel"/></Button>,
          <Button key="submit" type="primary" loading={loadingModal} onClick={this.handleModalOk}>
            <IntlMessages id="common.save"/>
          </Button>,
        ]}
      >
        <Form >
          <Row>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="employees.startDate"/>
                )}
              >
                <InputGroup compact>
                  {getFieldDecorator('startDay', {
                    initialValue: startDate.length === 19 ? moment(startDate) : null,
                    rules: [{type: 'object', required: true, message: <IntlMessages id="validation.selectDate"/>}],
                  })(
                    <DatePicker
                      format={dateFormat}
                      style={{width: 'calc(100% - 120px)'}}
                      disabledDate={(startValue) => {
                        if (!startValue || !endDate) {
                          return false;
                        }
                        return startValue.valueOf() > moment(endDate).valueOf();
                      }}
                      onChange={(_, date) => {
                        const Date = moment(date, dateFormat).format('YYYY-MM-DD');
                        this.handleInputChange([
                            { name:'startDay', value: Date },
                            { name:'startDate', value: Date + ' ' + (startTime ? startTime : '09:00:00') }
                          ]
                        )
                      }}
                    />
                  )}
                  {getFieldDecorator('startTime', {
                    initialValue: startDate.length === 19 ? moment(startDate) : null,
                    rules: [{type: 'object', required: true, message: 'Please select time!'}],
                  })(
                    <TimePicker
                      style={{width: 120}}
                      format='HH:mm'
                      minuteStep={15}
                      onChange={(_, date) => {
                        const time = date+':00';
                        this.handleInputChange([
                            {name:'startTime', value:time},
                            {name:'startDate', value:startDay + ' ' + time}
                          ]
                        )
                      }}
                    />
                  )}
                </InputGroup>

              </FormItem>
            </Col>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={<IntlMessages id="employees.endDate"/>}
              >
                <InputGroup compact>
                  {getFieldDecorator('endDay', {
                    initialValue: endDate.length === 19 ? moment(endDate) : null,
                    rules: [{type: 'object', required: true, message: <IntlMessages id="validation.selectDate"/>}],
                  })(
                    <DatePicker
                      format={dateFormat}
                      style={{width: 'calc(100% - 120px)'}}
                      disabledDate={(endValue) => {
                        if (!endValue || !startDate) {
                          return false;
                        }
                        return endValue.valueOf() <= moment(startDate).valueOf();
                      }}
                      onChange={(_, date) => {
                        const Date = moment(date, dateFormat).format('YYYY-MM-DD');
                        this.handleInputChange([
                            { name:'endDay', value: Date },
                            { name:'endDate', value: Date + ' ' + (endTime ? endTime : '18:00:00') }
                          ]
                        )
                      }}
                    />
                  )}
                  {getFieldDecorator('endTime', {
                    initialValue: endDate.length === 19 ? moment(endDate) : null,
                    rules: [{type: 'object', required: true, message: 'Please select time!'}],
                  })(
                    <TimePicker
                      style={{width: 120}}
                      format='HH:mm'
                      minuteStep={15}
                      onChange={(_, date) => {
                        const time = date+':00';
                        this.handleInputChange([
                            {name:'endTime', value:time},
                            {name:'endDate', value:endDay + ' ' + time}
                          ]
                        )
                      }}
                    />
                  )}
                </InputGroup>

              </FormItem>
            </Col>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="employees.timeOffType"/>
                )}
              >
                {getFieldDecorator('leaveTypeId', {
                  initialValue: leaveTypeId,
                  rules: [{required: true, message: intl.formatMessage({id:'validation.required'})}],
                })(
                  <Select onChange={(value) => { this.handleInputChange({name:'leaveTypeId', value}) }}>
                    { leaveTypes.map((type) => <Option key={type.id} value={type.id}><IntlMessages id={"leaves."+type.name}/></Option>) }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={<IntlMessages id="employees.requestedDay"/>}
              >

                <InputNumber
                  style={{width: '100%'}}
                  value={usedDays}
                  disabled={true}
                  onChange={(value) => {  this.handleInputChange({name: 'usedDays', value: value ? parseFloat(value) : 0}) }}
                  formatter={ value => value ? parseFloat(value) : 0 }/>

              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="employees.description"/>
                )}
              >
                {getFieldDecorator('comment', {
                  initialValue: comment,
                })(
                  <TextArea onChange={this.handleInputChange}
                            autosize={{minRows: 3, maxRows: 6}}/>
                )}
              </FormItem>
            </Col>

          </Row>
        </Form>
      </Modal>
    );
  };
}



const RequestForm = Form.create()(injectIntl(Request));

const mapStateToProps = ({auth, settings}) => {
  const {token, company} = auth;
  const {locale} = settings;
  return {locale, token, company}
};


export default connect(mapStateToProps)(RequestForm);
