import React from "react";
import {Link} from "react-router-dom";
import axios from 'util/Api'
import { apiUrl } from 'util/config'
import IntlMessages from "util/IntlMessages";
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Icon,
  Input, InputNumber,
  Menu, Modal,
  Radio,
  Row, Select, Switch,
  Table, TimePicker,
  TreeSelect,
} from "antd";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {B, T} from "../../helpers";
import moment from "moment";


const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;
const InputGroup = Input.Group;




class Request extends React.Component {

  defaultAllowance = {
    affectPayroll: false,
    amount: '',
    total: 0,
    approvalDate: '',
    approvalSteps: '',
    cancellationReason: '',
    currency: this.props.intl.formatMessage({id:'options.currency'}),
    currentAmount: '',
    currentInstallment: '',
    date: '',
    description: '',
    formattedDate: '',
    installmentPlan: '',
    isGross: false,
    paid: false,
    rejectReason: '',
    type: 'allowance',
  };

  state = {
    employees: [],
    modal: false,
    allowance: this.defaultAllowance,
    loadingModal: false
  };

  componentDidMount() {
    this.props.shareMethods(this.openAllowanceModal.bind(this),"openAllowanceModal");
    this.localizeDateTime();
  }

  openAllowanceModal() {
    this.props.form.resetFields();
    this.setState({ allowance: this.defaultAllowance, modal: true });
  };

  closeModal = () => {
    this.setState({modal: false});
  };

  localizeDateTime = () => {
    const intl = this.props.intl;
    const lng_code = intl.formatMessage({id:'options.dateTimeLocale'});
    import('moment/locale/'+lng_code);
  };

  handleModalOk = () => {

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loadingModal: true});
        axios.post('api/transaction/request', {
          routeCode: 'Transaction-7',
          allowance: this.state.allowance
        }).then(({data}) => {
          this.setState({ loadingModal: false });
          if (data.result) {
            console.log(data);
            T.toast('success', 'requestDone');
            this.setState({ modal: false });
          } else {
            console.log(data);
            T.toast('error');
          }
        }).catch((error) => {
          console.log(error.message);
          T.toast('error');
        });
      }
    });

  };

  handleInputChange = (event, state = 'allowance') => {

    if(Array.isArray(event)){
      const inputs = {};
      event.map((input) => {
        inputs[input.name] = input.value;
      });

      this.setState({
        [state]: { ...this.state[state], ...inputs}
      });
      setTimeout(() => {
        const { startDate, endDate } = this.state.allowance;
        const usedDays = startDate && endDate ? moment(endDate).diff(moment(startDate), 'days')+
          (((moment(endDate).unix()-moment(startDate).unix()) % 86400) >= 18000 ? 1 : 0.5) : 0;
        this.setState({allowance: {...this.state.allowance, usedDays}});
      }, 200);


    }else {
      let name, value;
      if(event.target){
        const target = event.target;
        value = target.type === 'checkbox' ? target.checked : target.value;
        name = target.id;
      }else{
        value = event.value;
        name = event.name;
      }
      this.setState({
        [state]: { ...this.state[state],
          [name]: value
        }
      });
    }

    setTimeout(() => {console.log(this.state)}, 200);
  };

  render(){
    const intl = this.props.intl;
    const dateFormat = intl.formatMessage({id:'options.dateTimeFormat'});
    const {getFieldDecorator} = this.props.form;

    const { loadingModal, modal} = this.state;

    const { amount, date, description, installmentPlan} = this.state.allowance;
    return (
      <Modal
        width={650}
        visible={modal}
        title={<IntlMessages id="requests.requestAllowance"/>}
        onOk={this.handleModalOk}
        onCancel={this.closeModal}
        footer={[
          <Button key="back" onClick={this.closeModal}><IntlMessages id="common.cancel"/></Button>,
          <Button key="submit" type="primary" loading={loadingModal} onClick={this.handleModalOk}>
            <IntlMessages id="common.save"/>
          </Button>,
        ]}
      >
        <Form >
          <Row>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="employees.amount"/>
                )}
              >
                <InputGroup compact>
                  {getFieldDecorator('amount', {
                    initialValue: amount,
                    rules: [{required: true, message: intl.formatMessage({id:'validation.required'})}],
                  })(
                    <InputNumber
                      style={{width: 'calc(100% - 80px)'}}
                      onChange={(value) => { this.handleInputChange({name: 'amount', value: parseFloat(value)}) }}
                      formatter={value => T.moneyFormatter(value)}
                    />
                  )}
                  <Select defaultValue={intl.formatMessage({id:'options.currency'})}
                          style={{width: 80}}
                          onChange={(value) => { this.handleInputChange({name: 'currency', value}) }}>
                    {B.currencies.map((cur) =>  <Option key={cur} value={cur}>{cur}</Option> )}
                  </Select>
                </InputGroup>

              </FormItem>
            </Col>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="employees.date"/>
                )}
              >
                {getFieldDecorator('date', {
                  initialValue: date ? moment(date) : null,
                  rules: [{type: 'object', required: true, message: <IntlMessages id="validation.selectDate"/>}],
                })(
                  <DatePicker
                    className="gx-mb-3 gx-w-100"
                    format={dateFormat}
                    onChange={(_, date) => { this.handleInputChange({name:'date', value: moment(date, dateFormat).format(B.dateFormat)}) }}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="employees.installment"/>
                )}
              >
                {getFieldDecorator('installmentPlan', {
                  initialValue: installmentPlan,
                  rules: [{required: true, message: intl.formatMessage({id:'validation.required'})}],
                })(
                  <Select onChange={(value) => { this.handleInputChange({name:'installmentPlan', value}) }}>
                    { T.range(1,13).map((n) => <Option key={n} value={n}>{n}</Option>) }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col sm={12} xs={24}>
              <FormItem
                {...B.formItemLayout}
                label={(
                  <IntlMessages id="employees.description"/>
                )}
              >
                {getFieldDecorator('description', {
                  initialValue: description,
                })(
                  <Input onChange={this.handleInputChange}/>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>

    );
  };
}



const RequestForm = Form.create()(injectIntl(Request));

const mapStateToProps = ({auth, settings}) => {
  const {token, company} = auth;
  const {locale} = settings;
  return {locale, token, company}
};


export default connect(mapStateToProps)(RequestForm);
